/**Resets*/
* {
  scroll-behavior: smooth;
}
body {
  font-size: 17px;
  line-height: 1.4705882353;
  font-weight: 400;
  letter-spacing: -0.022em;
  color: #1d1d1f;
  font-synthesis: none;
  -webkit-font-smoothing: antialiased;
  direction: ltr;
  text-align: left;
}

h1 {
  font-size: 48px;
  line-height: 1.0416666667;
  font-weight: 500;
  letter-spacing: 0.006em;
  margin-bottom: 0;
}

h2 {
  margin-bottom: 8px;
  font-size: 42px;
  line-height: 1.05;
  font-weight: 500;
  letter-spacing: 0.008em;
}
h2 span {
  font-size: 38px;
  line-height: 1.125;
  font-weight: 600;
  letter-spacing: 0.004em;
  padding: 75px 0 12px;
  text-align: center;
}
h3 {
  font-size: 34px;
  line-height: 1.2273927273;
  font-weight: 500;
  letter-spacing: 0.016em;
}
p,
.affa-feature-img > a,
.affa-counter p,
.affa-testimonial-startup,
.footer-widgets p,
.footer-widgets li,
.primarySection p {
  font-size: 19px !important;
}
.primarySection .content h2 {
  text-transform: uppercase !important;
}
.affa-feature-img > h4 {
  font-size: 28px;
}

.content .image {
  margin: auto;
}

.navigation .nav .menu-item a {
  font-size: 1em;
  font-family: inherit;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  text-align: inherit;
}

/*SubHeader2 */

#header .sub-header2 {
  height: 100%;
}
.sub-header-overlay {
  position: relative;
  z-index: 2;
  min-height: 30vh;
  align-items: center;
  display: flex;
}

#header .sub-header2 .bgfeature_video {
  display: block;
  position: absolute;
  top: 0px;
  right: 0px;
  min-width: 100%;
  min-height: 100% !important;
  height: auto;
  width: auto;
  z-index: -1;
  transform: translateX(0) translateY(-40%);
  filter: brightness(45%);
}
#header .sub-header2 .sub-header-overlay h1.header-title {
  font-size: 64px !important;
}
#header .sub-header2 .sub-header-overlay p {
  font-size: 32px;
}
#header .sub-header2 .sub-header-overlay span {
  font-size: 21px;
}

#header .sub-header2 .sub-header-overlay {
  padding: 10% 0;
  height: 100%;
  z-index: 200;
}
/*Background subheader*/

.wrapfeature_video_bg {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 0;
}

@media only screen and (max-width: 1024px) {
  /* SubHeader2 */
  #header .sub-header2 .sub-header-overlay {
    padding: 5% 0;
    height: 100%;
    z-index: 200;
  }
  #header .sub-header2 .sub-header-overlay h1.header-title {
    font-size: 42px !important;
  }
  #header .sub-header2 .sub-header-overlay p {
    font-size: 28px !important;
  }
  #header .sub-header2 .sub-header-overlay span {
    font-size: 17px !important;
  }
}
@media only screen and (max-width: 734px) {
  /**Resets*/
  h1 {
    font-size: 32px;
    line-height: 1.0970341935;
    font-weight: 500;
    letter-spacing: 0.011em;
  }
  .header-title {
    font-size: 32px !important;
    line-height: 1.0970341935;
    font-weight: 500;
    letter-spacing: 0.011em;
  }
  h2 {
    font-size: 28px;
    line-height: 1.125;
    font-weight: 600;
    letter-spacing: 0.004em;
  }
  h2 span {
    font-size: 24px;
    line-height: 1.16667;
    font-weight: 600;
    letter-spacing: 0.009em;
  }

  h3 {
    font-size: 19px;
    line-height: 1.2631578947;
    font-weight: 500;
    letter-spacing: 0.018em;
  }
  p {
    font-size: 17px !important;
  }
  .sub-header-overlay p {
    font-size: 21px !important;
  }
  .sub-header-overlay span {
    font-size: 17px !important;
  }
  .row .image {
    margin: auto;
  }
}
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.loading img {
  max-width: 64px;
  height: auto;
}
/* FEATURES */

#features {
  padding-top: 48px;
}
#features p {
  min-height: 180px;
}
#features .row {
  padding: 0px 15px;
}
#features .col-sm-4 {
  padding-bottom: 10px;
}

/* TESIMONIALS */
#testimonials .dot {
  background: #84b014;
}
@media only screen and (max-width: 734px) {
  #features .col-sm-4 {
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.2), 0px 7px 8px rgba(0, 0, 0, 0.1);
  }
  /**SubHeader2*/
}

/*CASES TESTMONIALS*/

#testimonials img {
  max-height: 150px;
  width: auto;
  margin: auto auto;
}

#testimonials .affa-testimonial-startup ul {
  list-style: none;
}
#testimonials .affa-testimonial-startup ul li::before {
  font-family: 'Ionicons';
  content: '\f121';
  margin-right: 15px;
  color: #84b014;
}
#primarySection .content ul {
  list-style: none !important;
}
#primarySection .content ul li {
  display: flex;
  flex-wrap: nowrap;
}
#primarySection .content ul li::before {
  font-family: 'Ionicons';
  content: '\f121';
  margin-right: 15px;
  color: #84b014;
}

#bottom .affa-callout h4 {
  font-size: 17px !important;
  line-height: normal;
}

#bottom input[type='color'],
#bottom input[type='date'],
#bottom input[type='datetime'],
#bottom input[type='datetime-local'],
#bottom input[type='email'],
#bottom input[type='month'],
#bottom input[type='number'],
#bottom input[type='password'],
#bottom .ie input[type='range'],
#bottom .ie9 input[type='range'],
#bottom input[type='search'],
#bottom input[type='tel'],
#bottom input[type='text'],
#bottom input[type='time'],
#bottom input[type='url'],
#bottom input[type='week'],
#bottom select {
  height: 45px;
}
#bottom input[type='submit'] {
  padding: 15px 24px;
}

/**Team*/
.team {
  padding-bottom: 40px;
  background-color: #fff;
}
@media only screen and (max-width: 1280px) and (max-height: 800px) {
  /* subHeader2 */
  #header .sub-header2 .sub-header-overlay {
    height: 60vh !important;
    align-items: center;
  }
  #header .sub-header2 .sub-header-overlay h1.header-title {
    font-size: 28px !important;
    line-height: normal;
    margin-top: 15px;
  }
  #header .sub-header2 .bgfeature_video {
    transform: translateX(0) translateY(-30%);
  }

  #header .sub-header2 .sub-header-overlay p {
    font-size: 19px !important;
    line-height: 30px;
    margin: 10px auto;
  }
  #header .sub-header2 .sub-header-overlay span {
    font-size: 16px !important;
    line-height: normal;
  }

  #header .sub-header2 .sub-header-overlay {
    height: 100%;
    z-index: 200;
  }
}
@media only screen and (max-width: 600px) {
  /* subHeader2 */
  #header .sub-header2 .sub-header-overlay {
    align-items: flex-start;
    height: 100% !important;
  }
  #header .sub-header2 .sub-header-overlay h1.header-title {
    font-size: 28px !important;
    line-height: normal;
    margin-top: 15px;
  }
  #header .sub-header2 .bgfeature_video {
    transform: translateX(0) translateY(-20%);
  }

  #header .sub-header2 .sub-header-overlay p {
    font-size: 19px !important;
    line-height: 30px;
    margin: 10px auto;
  }
  #header .sub-header2 .sub-header-overlay span {
    font-size: 16px !important;
    line-height: normal;
  }

  #header .sub-header2 .sub-header-overlay {
    height: 100%;
    z-index: 200;
  }
}

@media only screen and (max-width: 359px) {
  #header .sub-header2 .sub-header-overlay h1.header-title {
    font-size: 22px !important;
  }
  #header .sub-header2 .sub-header-overlay p {
    font-size: 17px !important;
  }
  #header .sub-header2 .sub-header-overlay span {
    font-size: 12px !important;
  }
}
